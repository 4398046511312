import { GrantType } from '../../constants';
import { IOAuth2DTO } from '../../models';
import { ContentType, HttpError, IApi } from '../../utils';
import { ICredentials } from '../../view-models';

import {
  InvalidGrantError,
  InvalidPasswordError,
  KeycloakAuthError,
  PasswordExpiredError,
  UserDisabledError,
  UserPermanentlyLockedOutError,
  UserTemporaryDisabledError,
} from './errors';

export type AuthRequestBody = ICredentials & {
  client_id: string;
  grant_type: GrantType;
};

export class AuthService {
  public constructor(private api: IApi) {}

  public async get(credentials: ICredentials): Promise<IOAuth2DTO> {
    const { refresh_token } = credentials;

    const body: AuthRequestBody = {
      ...credentials,
      client_id: 'sgc-api',
      grant_type: refresh_token ? GrantType.RefreshToken : GrantType.Password,
    };

    let res;
    try {
      res = await this.api.post({
        url: 'token',
        headers: { 'Content-Type': ContentType.FormUrlEncoded },
        body,
      });
    } catch (baseError) {
      if (baseError instanceof HttpError) {
        const error = baseError as HttpError;
        const kcError = [
          InvalidGrantError,
          InvalidPasswordError,
          PasswordExpiredError,
          UserDisabledError,
          UserPermanentlyLockedOutError,
          UserTemporaryDisabledError,
          KeycloakAuthError, // если не удалось уточнить конкретную ошибку, выбросим базовую. Эта строка обязательно должна быть в конце списка!
        ].find((err) => err.predicate(error.statusCode, error.errorBody));

        if (kcError) {
          throw new kcError(error.statusCode, error.errorBody);
        }
      }

      throw baseError;
    }
    return res.body;
  }
}
