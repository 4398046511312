import { GrantType } from '../constants';
import { AuthRequestBody } from '../services/auth/auth-service';
import { ContentType } from '../utils';

if (!process.env.REACT_APP_KK_URL) {
  throw new Error('Не задан путь до keycloak (REACT_APP_KK_URL)');
}

const baseUrl = process.env.REACT_APP_KK_URL;

export interface AsyncResponse {
  status: boolean;
  data?: any;
  codeError?: number;
}

export const refreshToken = async (refreshToken: string): Promise<AsyncResponse> => {
  const body: AuthRequestBody = {
    refresh_token: refreshToken,
    client_id: 'sgc-api',
    grant_type: GrantType.RefreshToken,
  };

  const preparedBody = Object.keys(body)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent((body as any)[key])}`;
    })
    .join('&');

  const options: RequestInit = { method: 'POST', headers: { 'Content-Type': ContentType.FormUrlEncoded }, body: preparedBody };

  const res = await fetch(`${baseUrl}token`, options);
  if (res.ok) {
    const data = await res.json();

    return { status: true, data };
  }

  return { status: false, codeError: res.status };
};
