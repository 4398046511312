import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import lodash from 'lodash';
import { reaction } from 'mobx';
import { MobXProviderContext, observer } from 'mobx-react';

import { Button, Form, FormButtons, FormFieldType, IMetadataField } from '../../../../components';
import { CloseButton } from '../../../../components/buttons/close-button';
import { FieldType } from '../../../../components/form/field-type';
import { PackageType, PurposeType } from '../../../../constants';
import { Stores } from '../../../../stores';
import { downloadFile, message, transformNumberFields, useConfirm } from '../../../../utils';
import { IFilePair, IMeasurement, IPackage } from '../../../../view-models';

import { HeliumPolishFile } from './helium-polish-file';
import { HelliumPolishGrid } from './hellium-polish-grid';
import { metadata as MultipleStonesMetadata } from './multiple-stones-metadata';
import { metadata as SingleStoneMetadata } from './single-stone-metadata';

export const Measurement: FC = observer(() => {
  const { dictionaryStore, measurementStore: store, packageStore, filesStore } = useContext(MobXProviderContext) as Stores;
  const { confirm } = useConfirm();
  const { packageId } = useParams();

  const [isFinishing, setIsFinishing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    reset,
    watch,
    setError,
    clearErrors,
  } = useForm<Partial<IMeasurement>>({
    values: {},
  });

  const file = watch('fileIdentity');
  const isWorkCompleted = watch('isWorkCompleted');

  const pack: IPackage | null = useMemo(() => packageStore.data, [packageStore.data]);

  const isSingleStone: boolean = useMemo(() => pack?.type === PackageType.SingleStone ?? false, [pack]);
  const isMultipleStones: boolean = useMemo(() => pack?.type === PackageType.MultipleStones ?? false, [pack]);

  const loadFormData = useCallback(
    async (packageId?: string) => {
      if (packageId) {
        try {
          setIsLoading(true);
          const data = await store.fetch(packageId);
          reset(data);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [reset, packageId, setIsLoading]
  );

  useEffect(() => {
    loadFormData(packageId);

    return reset;
  }, [loadFormData, packageId, reset]);

  useEffect(() => {
    const errorObserver = reaction(
      () => store.errors,
      (errors) => {
        if (errors) {
          Object.entries(errors).forEach(([key, value]) => {
            setError(key as keyof IMeasurement, {
              type: 'custom',
              message: value,
            });
          });
        }
      },
      {
        fireImmediately: true, // чтобы reaction срабатывал и в первый раз
        equals: (prev?: Record<keyof IMeasurement, string>, next?: Record<keyof IMeasurement, string>): boolean =>
          lodash.isEqual(prev, next),
        delay: 500,
      }
    );

    return () => {
      errorObserver?.();
      store.finalize();
    };
  }, [store, reset, setError, clearErrors]);

  const onChangeFile = useCallback(
    async (file?: IFilePair) => {
      if (!file?.file) {
        setValue('fileIdentity', null, { shouldDirty: true });
      } else {
        clearErrors();

        if (file.fileName.endsWith('.txt')) {
          setValue('fileIdentity', file.fileName, { shouldDirty: true });
          filesStore.set(file.fileName, file.file);
        } else {
          message.error('Файл должен иметь формат txt', 1000);
        }
      }
    },
    [setValue, clearErrors, filesStore]
  );

  const onDownloadFile = useCallback(
    (fileName: string) => {
      const blob = filesStore.get(fileName);
      if (blob) {
        downloadFile(blob, fileName);
      }
    },
    [filesStore]
  );

  const metadata: IMetadataField[] = useMemo(
    () => [
      ...(isSingleStone
        ? ([
            {
              name: 'fileIdentity',
              label: 'Helium Polish',
              type: FormFieldType.CUSTOM,
              className: 'helium-polish',
              template: (props) => (
                <HeliumPolishFile
                  value={props.value}
                  onChange={onChangeFile}
                  disabled={isWorkCompleted}
                  onDownload={onDownloadFile}
                />
              ),
            },
          ] as IMetadataField[])
        : []),
      ...(file
        ? [
            {
              id: `file`,
              name: 'fileIdentity',
              type: FormFieldType.CUSTOM,
              className: 'fullWith',
              template: () => <HelliumPolishGrid data={store.data as any} />,
            },
          ]
        : (isSingleStone && SingleStoneMetadata) || (isMultipleStones && MultipleStonesMetadata) || []),
    ],
    [file, isSingleStone, isMultipleStones, isWorkCompleted]
  );

  const onSubmit: SubmitHandler<Partial<IMeasurement>> = useCallback(
    async (data) => {
      if (packageStore.data && packageId) {
        try {
          const numberKeys = metadata.filter((m) => m.type === FieldType.FLOAT).map((m) => m.name);
          setIsSaving(true);
          const res = await store.save(packageStore.data, transformNumberFields(data, numberKeys) as IMeasurement);

          if (res === undefined) {
            return;
          }

          reset(res);
          if (data.fileIdentity) {
            message.success('Данные успешно загружены');
          } else {
            message.success('Данные сохранены. Вы можете вернуться к заполнению рабочего листа позже');
          }
        } finally {
          setIsSaving(false);
        }
      }
    },
    [store, packageId, metadata]
  );

  const onClickFinalize = useCallback(async () => {
    if (packageId) {
      try {
        setIsFinishing(true);
        const isConfirmed = await confirm({
          title: 'Завершение исследования',
          content: (
            <pre>
              Вы уверены, что хотите завершить исследование?
              <br />
              После этого редактирование блока "Параметры" будет недоступно
            </pre>
          ),
        });

        if (!isConfirmed) {
          return;
        }

        const completedResearch = await store.completeResearch(packageId);
        reset(completedResearch);
        // Для того чтобы обновить информацию (статус) в левом зелленом блоке
        packageStore.fetch(packageId, PurposeType.ForExpertise);
        message.success('Исследование завершено!');
      } finally {
        setIsFinishing(false);
      }
    }
  }, [packageId, store, reset]);

  return (
    <Form
      className={'worksheet__measurement'}
      metadata={metadata}
      control={control}
      store={dictionaryStore}
      errors={errors}
      disabled={!!file || isWorkCompleted}
      isLoading={isLoading}
    >
      <FormButtons>
        <CloseButton />
        {!isWorkCompleted ? (
          <>
            <Button.Contained onClick={handleSubmit(onSubmit)} disabled={!isDirty} isLoading={isSaving}>
              Сохранить
            </Button.Contained>
            <Button.Outlined onClick={onClickFinalize} disabled={!store.data} isLoading={isFinishing}>
              Завершить исследование
            </Button.Outlined>
          </>
        ) : null}
      </FormButtons>
    </Form>
  );
});
