import lodash from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { Services } from '../services';
import { FormError, message } from '../utils';
import { IMeasurement, IPackage } from '../view-models';

import { FilesStore } from './files-store';

const DefaultMeasurementData: IMeasurement = {
  fileIdentity: null,
  isWorkCompleted: false,
  crownAngleDg: null,
  crownHeightPt: null,
  girdleThicknessPt: null,
  lowerHalfLengthPt: null,
  maxDiameterMm: null,
  maxHeightMm: null,
  minDiameterMm: null,
  minHeightMm: null,
  pavilionAngleDg: null,
  pavilionDepthPt: null,
  starLengthPt: null,
  tableSizePt: null,
  totalDepthPt: null,
  totalHeightMm: null,
};

export class MeasurementStore {
  @observable public data: IMeasurement | undefined = undefined;
  @observable public errors: Record<keyof IMeasurement, string> | undefined = undefined;

  public constructor(
    private services: Services,
    private filesStore: FilesStore
  ) {
    makeObservable(this);
  }

  public fetch = async (packageId: IPackage['packageId']): Promise<IMeasurement> => {
    const res = await this.services.measurement.get(packageId);
    runInAction(() => {
      this.data = res ? observable(res) : undefined;
      if (res?.fileIdentity) {
        this.filesStore.fetch(`worksheet/helium-measure-singlestone-download/${packageId}`, res?.fileIdentity);
      }
    });
    return this.toViewModel(res);
  };

  public save = async (pack: IPackage, data: IMeasurement): Promise<IMeasurement | undefined> => {
    let res: IMeasurement | null = null;

    try {
      const file = data.fileIdentity ? this.filesStore.get(data.fileIdentity) : null;
      res = await this.services.measurement.save(pack, { ...data, file }, this.data);

      runInAction(() => {
        this.data = res ? observable(res) : undefined;
      });

      return this.toViewModel(res);
    } catch (error) {
      if (error instanceof FormError) {
        if (error.statusCode === 422) {
          message.error('В файле есть ошибки. Проверьте его на наличие дублирующихся параметров');
        }

        runInAction(() => {
          this.errors = (error as FormError).errorBody as Record<string, string>;
        });
      } else {
        throw error;
      }
    }
  };

  @action
  public finalize = (): void => {
    this.errors = undefined;
    this.data = undefined;
  };

  public completeResearch = async (packageId: IPackage['packageId']): Promise<IMeasurement> => {
    return await this.services.measurement.finalize(packageId);
  };

  private toViewModel = (data: any): IMeasurement => {
    return lodash.pick({ ...DefaultMeasurementData, ...data }, [
      'fileIdentity',
      'isWorkCompleted',
      'crownAngleDg',
      'crownHeightPt',
      'girdleThicknessPt',
      'lowerHalfLengthPt',
      'maxDiameterMm',
      'maxHeightMm',
      'minDiameterMm',
      'minHeightMm',
      'pavilionAngleDg',
      'pavilionDepthPt',
      'starLengthPt',
      'tableSizePt',
      'totalDepthPt',
      'totalHeightMm',
    ]);
  };
}
