import { refreshToken } from '../../newApi/auth';
import { interpolateTemplateWithParameters } from '../interpolate-template-with-parameters';
import { UrlHelper } from '../url-helper';

import { ContentType } from './constants/content-type';
import { FormError, HttpError, ProcessedError } from './errors';
import { IRequestParamsDTO, IServerResponseDTO, RequestMethod } from './models';
import { toFormData } from './to-form-data';

/**
 * Класс для выполнения произвольных http-запросов
 */
export class Http {
  private _apiBaseUrl: string | null = null;

  public constructor(
    apiBaseUrl: string,
    private isPivate: boolean = false
  ) {
    this.setApiBaseUrl(apiBaseUrl);
  }

  public setApiBaseUrl = (apiBaseUrl: string) => {
    this._apiBaseUrl = apiBaseUrl;
  };

  public get apiBaseUrl(): string | null {
    return this._apiBaseUrl;
  }

  public async get(params: IRequestParamsDTO): Promise<IServerResponseDTO> {
    return this.httpRequest({ ...params, method: 'GET' });
  }

  public async post(params: IRequestParamsDTO): Promise<IServerResponseDTO> {
    return this.httpRequest({ ...params, method: 'POST' });
  }

  public async put(params: IRequestParamsDTO): Promise<IServerResponseDTO> {
    return this.httpRequest({ ...params, method: 'PUT' });
  }

  public async patch(params: IRequestParamsDTO): Promise<IServerResponseDTO> {
    return this.httpRequest({ ...params, method: 'PATCH' });
  }

  public async del(params: IRequestParamsDTO): Promise<IServerResponseDTO> {
    return this.httpRequest({ ...params, method: 'DELETE' });
  }

  /**
   * Обобщенный метод для выполнения любого из GET, POST, PUT, PATCH, DELETE HTTP-запросов
   * @param params - Параметры запроса
   * @return Promise с ответом сервера
   */
  public async httpRequest(params: IRequestParamsDTO & { method: RequestMethod }): Promise<IServerResponseDTO> {
    const fullUrl = this.getFullUrl(
      `${this._apiBaseUrl}${interpolateTemplateWithParameters(params.url, params.urlParams, encodeURIComponent)}`,
      params.query
    );
    try {
      const headers = this.prepareRequestHeaders(params.headers, params.method);
      const body = this.prepareRequestBody(headers, params.body, params.method);

      const options: RequestInit = {
        method: params.method,
        mode: 'cors', // https://fetch.spec.whatwg.org/#concept-request-mode
        // В случае проблем чтобы cookies отправлялись нужно заменить на 'include'
        // и сервер должен отвечать в Access-Control-Allow-Origin не '*', а конкретный домен
        credentials: 'same-origin',
        headers,
        body,
      };

      let response = await fetch(fullUrl, options);
      const isError401 = response.status === 401;
      const isLoginPage = window.location.pathname.includes('login');

      if (isError401 && !isLoginPage) {
        const responseAfterRefresh = await this.handle401HttpStatus(fullUrl, options, params.method);

        if (responseAfterRefresh) {
          response = responseAfterRefresh;
        }
      }
      // clone() необходим, т.к. нельзя одновременно иметь доступ и к body, и к blob
      // Собственно для таких ситуаций clone() и нужен
      // https://developer.mozilla.org/en-US/docs/Web/API/Response/clone
      const blob = response.clone().blob();
      const handledResponseBody = await this.handleResponseBody(response, params.method);

      this.handleUnsuccessHttpStatus(response.status, handledResponseBody);

      return Promise.resolve<IServerResponseDTO>({
        url: fullUrl,
        body: handledResponseBody,
        headers: response.headers,
        statusCode: response.status,
        blob,
      });
    } catch (error) {
      return Promise.reject<any>(error);
    }
  }

  private async handle401HttpStatus(
    fullUrl: string,
    options: RequestInit,
    method: RequestMethod
  ): Promise<Response | undefined> {
    const session = window.localStorage.getItem('auth-info');

    if (session) {
      const refresh_token = JSON.parse(session).refresh_token;

      const { status, data } = await refreshToken(refresh_token);

      if (status && data && options.headers) {
        window.localStorage.setItem('auth-info', JSON.stringify(data));

        const headers = this.prepareRequestHeaders(options.headers, method);
        this.addAuthInfo(headers);
        options.headers = headers;

        return await fetch(fullUrl, options);
      }

      window.localStorage.removeItem('auth-info');
      window.location.replace('/login');
    }
  }

  private getFullUrl(initialUrl: string, query?: object): string {
    if (query) {
      const queryString = UrlHelper.queryString(query);
      if (queryString) {
        return `${initialUrl}?${queryString}`;
      }
    }

    return initialUrl;
  }

  private prepareRequestHeaders(initialHeaders: IRequestParamsDTO['headers'], method: RequestMethod): Headers {
    const headers = new Headers(initialHeaders ?? {});

    this.adjustContentType(headers, method);
    this.addAuthInfo(headers);

    return headers;
  }

  private adjustContentType(headers: Headers, method: RequestMethod): void {
    if (method !== 'GET' && !headers.has('Content-Type')) {
      headers.set('Content-Type', ContentType.JSON);
    }
  }

  private addAuthInfo(headers: Headers): void {
    const session = window.localStorage.getItem('auth-info');
    if (session && this.isPivate) {
      const token = JSON.parse(session);
      // 'Authorization': 'Bearer access_token_text'
      headers.set('Authorization', `${token.token_type} ${token.access_token}`);
    }
  }

  private prepareRequestBody(headers: Headers, body: IRequestParamsDTO['body'], method: RequestMethod): RequestInit['body'] {
    let preparedBody;
    if (method !== 'GET' && body) {
      switch (headers.get('Content-Type')) {
        case ContentType.JSON: {
          preparedBody = JSON.stringify(body);
          break;
        }
        case ContentType.Text: {
          preparedBody = body;
          break;
        }
        case ContentType.FormUrlEncoded: {
          // https://github.com/github/fetch/issues/263#issuecomment-209548790
          preparedBody = Object.keys(body)
            .map((key) => {
              return `${encodeURIComponent(key)}=${encodeURIComponent((body as any)[key])}`;
            })
            .join('&');
          break;
        }
        case ContentType.FormData: {
          preparedBody = toFormData(body);
          // 'Content-Type': 'multipart/form-data' НЕЛЬЗЯ задавать ВРУЧНУЮ
          // https://github.com/github/fetch/issues/505#issuecomment-293064470
          // Его ДОЛЖЕН добавить БРАУЗЕР на основе body
          // При добавлении вручную браузер считает что мы тогда делаем все сами
          // И не добавляет важный дополнительный параметр для этого заголовка - boundary
          // Без него весь заголовок становиться невалидным и передача файлов не работает
          headers.delete('Content-Type');
          break;
        }
        default:
          console.warn(`Неизвестный Content-Type: ${headers.get('Content-Type')}`);
      }
    }

    return preparedBody;
  }

  private handleUnsuccessHttpStatus(status: number, body: any): void {
    if (status >= 400) {
      const CurrentError = [FormError, ProcessedError].find((err) => err.predicate(status, body)) ?? HttpError;

      throw new CurrentError(status, body);
    }
  }

  private async handleResponseBody(res: Response, method: RequestMethod): Promise<any> {
    let body: any;
    const contentType = (res.headers.get('Content-Type') ?? '') as ContentType;

    if ([ContentType.JSON, ContentType.ProblemJSON].some((type) => contentType.includes(type))) {
      // обратываем body как json
      try {
        body = await res.json();
      } catch (error) {
        // невалидный JSON
        console.error(error);
        body = null;
      }
    } else if ([ContentType.Text].some((type) => contentType.includes(type))) {
      // обратываем body как text
      try {
        body = await res.text();
      } catch (error) {
        console.error(error);
        body = null;
      }
    } else {
      // обратываем body как blob
      try {
        body = await res.blob();
      } catch (error) {
        console.error(error);
        body = null;
      }
    }

    // В случае если backend вернет строку при создании объекта оборачиваем его в объект
    if (method === 'POST' && typeof body === 'string' && body !== '' && res.status >= 200 && res.status < 300) {
      body = { id: body };
    }

    return Promise.resolve(body);
  }
}
